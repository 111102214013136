
export default {
    // ========================================
    async loadingTextOneFile(file) {        
        let dataRaw = file.raw;
        let resultText = await new Promise((resolve) => {
            let reader = new FileReader();
            reader.onload = (e) => resolve(reader.result);
            reader.readAsText(dataRaw);
        });

        return resultText;
    },

    // ========================================
    async loadingTextMultiFiles(files) {

        let returnData = [];

        for (let i = 0; i < files.length; i++) {
            let fileData = await this.loadingTextOneFile(files[i]);
            if (fileData !== null &&
                fileData !== undefined &&
                fileData.data !== null &&
                fileData.data !== undefined) {
                returnData.push(fileData);
            }
        }

        return returnData;
    },

    // ========================================
    convertCSVToJson(csvData) {
        // Split on row
        var f = csvData.split("\r\n");

        // Get first row for column headers
        var headers = f.shift().split("\t");

        var json = [];
        f.forEach(function (d) {
            d = d.trim();
            if (d.length > 0) {
                // Loop through each row
                var tmp = {}
                var row = d.split("\t")
                for (var i = 0; i < headers.length; i++) {
                    tmp[headers[i]] = row[i];
                }
                // Add object to list
                json.push(tmp);
            }
        });

        return json;
    },

    // ========================================    
    convertMLBToJson(mlbData) {
        // Split on row
        var f = mlbData.split("\r\n");

        // configName khong xai
        var configName = f.shift().split("\t");

        // Get first row for column headers
        var headers = f.shift().split("\t");

        // valueType khong xai
        var valueTypes = f.shift().split("\t");

        let keyIndex = -1;
        for (let i = 0; i < valueTypes.length; i++) {
            let type = valueTypes[i]            
            if (type.indexOf("|KEY") !== -1) {
                keyIndex = i;
            }
        }

        let json = undefined;
        if (keyIndex >= 0) {
            json = {};
        }
        else {
            json = [];
        }

        f.forEach(function (d) {
            d = d.trim();
            if (d.length > 0) {
                // Loop through each row
                var tmp = {}
                var row = d.split("\t");
                let keyName = undefined;

                if (keyIndex >= 0) {
                    keyName = row[keyIndex];
                }

                for (var i = 0; i < headers.length; i++) {

                    if (i === keyIndex) {
                        continue;
                    }

                    if (row[i] !== undefined) {
                        if (!isNaN(row[i])) {
                            tmp[headers[i]] = Number(row[i]);
                        }
                        else {
                            try {
                                let tempJson = JSON.parse(row[i]);
                                tmp[headers[i]] = tempJson;
                            } catch (e) {
                                let tempValue = `${row[i]}`;
                                tempValue = tempValue.replace(`"`, ``);
                                tmp[headers[i]] = tempValue;
                            }
                        }
                    }
                }

                // Add object to list
                if (keyName !== undefined) {
                    json[keyName] = tmp;
                }
                else {
                    json.push(tmp);
                }
            }
        });

        return json;
    },

    // ========================================
    convertMLBToES6(mlbData) {
        // Split on row
        var f = mlbData.split("\r\n");

        // configName khong xai
        var configName = f.shift().split("\t");

        // Get first row for column headers
        var headers = f.shift().split("\t");

        let jsonFormat = JSON.stringify(this.convertMLBToJson(mlbData), null, 4);

        let replaceJsonFormat = jsonFormat.replaceAll(/"\w+":/g, (match) => { return match.replaceAll(`"`, ``); });
        replaceJsonFormat = replaceJsonFormat.replaceAll("    ", "\t");

        let returnString = `const ${configName[0]} = ${replaceJsonFormat}` + "\n\n" + `export default ${configName[0]};`;

        return returnString;
    }
}