import request from '@/utils/request-service-admin.js'
import requestClanLDB from '@/utils/request-service-clanldb'

export default {
    // ========================================
    async addClanLDBSettings(eventID, eventType, startTimestamp, endTimestamp, segmentConfig, rewardsConfig, owner) {
        return await request({
            url: '/admintool/clanldb/add-clanldb-settings',
            method: 'post',
            data: {
                eventID: eventID,
                eventType: eventType,
                startTimestamp: startTimestamp,
                endTimestamp: endTimestamp,
                segmentConfig: segmentConfig,
                rewardsConfig: rewardsConfig,
                owner: owner
            }
        })
    },

    // ========================================
    async getAllClanLDBSettings() {
        return await request({
            url: '/admintool/clanldb/get-all-clanldb-settings',
            method: 'get',
            data: {}
        })
    },

    // ========================================
    async deleteClanLDBSettings(ldbGroupName) {
        return await request({
            url: '/admintool/clanldb/delete-clanldb-settings',
            method: 'post',
            data: {
                ldbGroupName: ldbGroupName
            }
        })
    },

    // ========================================
    async resetClanLDBSettings(ldbGroupName, startTimestamp, endTimestamp) {
        return await request({
            url: '/admintool/clanldb/reset-clanldb-settings',
            method: 'post',
            data: {
                ldbGroupName: ldbGroupName,
                startTimestamp: startTimestamp,
                endTimestamp: endTimestamp
            }
        })
    },

    // ========================================
    async getClanLDBLog(ldbGroupName) {
        return await request({
            url: '/admintool/clanldb/get-clanldb-log',
            method: 'post',
            data: {
                ldbGroupName: ldbGroupName,
            },
            responseType: 'blob'
        })
    },

    // ========================================
    async getUserScoreFullData(userID) {
        return await requestClanLDB({
            url: '/get-user-score-fulldata',
            method: 'post',
            data: {
                userID,
            }
        })
    },
}